.card-section-vert {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: auto !important;

    &__body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: calc(var(--gs) * 2) 0 0 0;
        flex: 1;
        min-height: 210px;
        height: 210px;
        @media (--tablet-lg-min) {
            padding: calc(var(--gs) * 4) 0;
        }
    }

    &__preview {
        height: calc(var(--gs) * 25);
        border: 1px solid var(--cl-divider-grey);
        border-radius: 5px;
        transition: box-shadow var(--time) var(--timing-function);

        &:hover {
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        }
    }

    &__img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover';
    }

    &__desc {
        margin-bottom: calc(var(--gs) * 2);
        font-size: 16px;
    }
    
    &__hl {
        font-size: 20px;
        line-height: 1.5rem;
        text-align: center;
        margin-bottom: calc(var(--gs) * 2);
    }
    &__link .link {
        font-size: 16px;
    }
}

.info-tag {
    display: block;
    padding: calc(var(--gs) * 0.5) var(--gs);
    color: var(--cl-global);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 8px;
    letter-spacing: 0.1em;
    line-height: 1rem;
    margin: 0;
    max-height: calc(var(--gs) * 3);
    border-top-left-radius: 5px;
    white-space: nowrap;
    text-align: center;
    cursor: default;

    @media (--tablet) {
        max-height: 18px;
    }

    &--pink {
        background: var(--cl-pink);
    }

    &--blue {
        background: var(--cl-lg-blue);
    }

    &--magenta {
         background: var(--cl-magenta);
    }

    &--blue2 {
        background: var(--cl-blue);
        color: var(--cl-white);
    }
}

.card-video-review {
    &__head {
        overflow: hidden;
        margin-bottom: calc(var(--gs) * 1);
        height: calc(var(--gs) * 12);
        display: flex;
        align-items: center;

        @media (--tablet-lg) {
            height: calc(var(--gs) * 8);
        }

        @media (--tablet) {
            margin-bottom: var(--gs);
            height: calc(var(--gs) * 5);
        }

        &-quote {
            fill: var(--cl-blue);
            width: 17px;
            height: 15px;
            position: relative;

            @media (--tablet) {
                width: 13px;
                height: 12px;
            }

            &:first-of-type {
                margin-right: calc(var(--gs) * 1.5);
                transform: rotate(180deg);
            }

            &:last-of-type {
                margin-left: calc(var(--gs) / 2);
            }
        }

        &-text {
            white-space: nowrap;
            /*max-width: calc(100% - 24px);*/
            overflow: unset !important;
            margin-bottom: 0;

            @media (--tablet) {
                max-width: calc(100% - 20px);
            }

            & span {
                & span {
                    white-space: normal;
                }
            }
        }
    }
    &__description {
        margin-bottom: calc(var(--gs) * 1);
        min-height: 1px; /* если элемент будет пустой, чтобы margin-bottom применился */
        
        &-course {
            margin-bottom: calc(var(--gs) * 2);
        }
    }

    &__body {
        transition: all var(--time) var(--timing-function);
        height: calc(var(--gs) * 28);

        @media (--tablet-lg) {
            height: calc(var(--gs) * 25);
        }

        @media (--tablet) {
            height: calc(var(--gs) * 20);
        }
    }

    &__iframe {
        width: 100%;
        height: 100%;
    }
}

.info-group {
    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        padding: 0;
        display: flex;
        align-items: center;

        &::before {
            display: none;
        }

        &::after {
            position: relative;
            margin: 0 var(--gs);
            display: block;
            content: ' ';
            border: 1px solid var(--cl-grey);
            height: 80%;
            width: 0;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }
}

.card-partner {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &__img {
        max-height: 70px;
        margin: auto;
    }

    &__desc {
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        text-align: center;
        color: var(--cl-grey);
    }
}

.price-panel {
    display: flex;
    flex-direction: column;

    &__prices {
        display: flex;
        align-items: baseline;
        margin-bottom: calc(var(--gs) * 1.5);
    }

    &__price {
        margin-bottom: 0;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;

        @media (--desktop) {
            margin-bottom: 0;
        }

        @media (--tablet) {
            font-size: 16px;
        }

        & + & {
            margin-left: var(--gs);
        }

        &--old {
            font-weight: 500;
            font-size: 14px;
            color: var(--cl-grey);
            text-decoration: line-through;

            @media (--tablet) {
                font-size: 13px;
            }
        }
    }

    &__btns {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &__btn {
        width: 100%;
    }
    &__credit {
        margin-bottom: calc(var(--gs));
    }
}
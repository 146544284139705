.table-company {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    &__row {
        &-cell {
            border: 1px solid var(--cl-divider-grey);
            overflow: hidden;
        }
    }
}

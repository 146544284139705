.info-panel {
    display: inline-flex;
    align-items: center;

    &__tag {
        font-weight: bold;
        font-size: 8px;
        height: calc(var(--gs) * 3);
        max-height: 100%;
        line-height: calc(var(--gs) * 3);
        padding: 0 calc(var(--gs) / 2);
        text-transform: uppercase;
        margin-right: calc(var(--gs) * 2);

        @media (--tablet) {
            margin-right: var(--gs);
        }
    }

    &__review-count {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
    }

    &__review-icon {
        margin: 0 calc(var(--gs) * 0.5);
    }

    &__rating {
        margin-right: calc(var(--gs) * 2);

        @media (--tablet) {
            margin-right: var(--gs);
        }
    }

    &__downloads {
        font-weight: 500;
        font-size: 11px;
        display: flex;
    }

    &__link {
        color: var(--cl-global);

        .icon {
            margin: 0 2px;
            color: var(--cl-global);
        }
    }
}

.ozon-modal .popup__body {
    padding: 0;
}

.popup {     
    width: 100%;
}

.modal-container { 
    background: none;
}

.modal-wrapper {     
    height: auto;
}
.card-section {
    display: flex;
    overflow: hidden;
    border: 1px solid var(--cl-divider-grey);
    border-radius: 5px;
    transition: box-shadow var(--time) var(--timing-function);

    &:hover {
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    }

    &__body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: calc(var(--gs) * 4) calc(var(--gs) * 3) calc(var(--gs) * 4) calc(var(--gs) * 7);
        flex: 1;
    }

    &__preview {
        height: calc(var(--gs) * 25);
    }

    &__img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover';
    }

    &__desc {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: calc(var(--gs) * 2);
    }
    
    &__hl {
        font-size: 1.125rem;
        line-height: 1.5rem;
    }
}

.card-course {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--cl-divider-grey);
    border-radius: 5px;
    background: var(--cl-white);
    transition: box-shadow var(--time) var(--timing-function);

    &:hover {
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    }

    &__tags {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        min-width: calc(var(--gs) * 10);
    }

    &__tag {
        font-weight: bold;
        font-size: 0.5rem;
        width: 100%;
        height: calc(var(--gs) * 3);
        max-height: 100%;
        line-height: calc(var(--gs) * 3);
        padding: 0 calc(var(--gs) / 2);
        text-transform: uppercase;
        margin-right: calc(var(--gs) * 2);

        &:not(:first-child) {
            border-top-left-radius: 0;
        }
    }

    &__detail {
        display: flex;
        position: relative;
        min-height: 53%;
        height: 53%;
        overflow: hidden;

        .card-course--tablet & {
            min-height: auto !important;
            height: auto !important;
        }

        .card-course--small & {
            min-height: 48%;
            height: 48%;
        }

        &-img {
            transition: all var(--time) var(--timing-function);
            filter: grayscale(1);
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            height: 100%;
            width: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover';

            .card-course--small & {
                object-fit: cover;
            }

            .card-course:hover & {
                transform: scale(1.1);
                filter: grayscale(0);
            }
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: calc(var(--gs) * 3) calc(var(--gs) * 7);

        .card-course--small & {
            padding: calc(var(--gs) * 3);
            @media (--tablet) {
                padding: calc(var(--gs) * 2);
            }
        }
    }

    &__top {
        display: flex;
        justify-content: space-between;
    }

    &__link {
        display: inline-block;
        color: var(--cl-black);
        font-size: 24px;

        .card-course--small & {
            font-size: 18px;
        }
    }

    &__top,
    &__link {
        margin-bottom: calc(var(--gs) * 1.5);
    }

    &__info {
        margin-bottom: auto;
        .card-course--small & {
            margin-bottom: calc(var(--gs) * 2);
        }
    }
    .line-clamp {
        margin-top: auto;
    }

    &__panel {
        order: 0;

        .card-course--small & {
            order: 1;
        }

        .info-panel__rating {
            margin-right: 0;
        }
    }

    &__date {
        order: 1;

        .card-course--small & {
            order: 0;
            padding: 0 var(--gs);
        }
    }

    &__price {
        flex-direction: row;
        margin-top: auto;

        .price-panel__btns {
            justify-content: center;
            width: 200px;
            flex: none;

            .card-course--small & {
                width: auto;
                flex: 1;
            }
        }

        .price-panel__btn {
            .card-course--small & {
                padding: var(--gs);
            }

            &.btn--wishlist {
                text-align: center;
                width: auto;
                min-width: calc(var(--gs) * 5);
                height: calc(var(--gs) * 5);
                padding: var(--gs);
            }
        }

        .price-panel__prices {
            align-items: flex-start;
            flex-direction: column;
            flex: 1;
            margin-bottom: 0;

            @media (--desktop-lg) {
                justify-content: flex-end;
            }

            .card-course--small & {
                justify-content: flex-end;
            }
        }

        .price-panel__price {
            margin-left: 0;

            .card-course--small & {
                @media (--tablet-lg-min) and (--desktop-lg) {
                    font-size: 18px;
                }
            }
        }

        .price-panel__price--old {
            .card-course--small & {
                @media (--tablet-lg-min) and (--desktop-lg) {
                    font-size: 14px;
                }
            }
        }
    }
}

.info-label {
    padding: 0 calc(var(--gs) * 2);
    height: calc(var(--gs) * 3);
    line-height: calc(var(--gs) * 3);
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    overflow: hidden;
    text-align: center;
    cursor: default;

    &--grey {
        background: var(--cl-elem-grey);
        color: var(--cl-global);
    }
}

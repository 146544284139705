.index {
    .header-main {
        .header-main__panel {
            border-bottom: transparent;

            .html-modal-open & {
                @media (--tablet-lg) {
                    border-bottom: 1px solid var(--cl-divider-grey);
                }
            }
        }

        .header-main__top {
            border-bottom: transparent;

            .html-modal-open & {
                @media (--tablet-lg) {
                    border-bottom: 1px solid var(--cl-divider-grey);
                }
            }
        }
    }

    &__section {
        margin: calc(var(--gs) * 2) 0;
        padding: calc(var(--gs) * 3) 0;

        &:first-of-type {
            margin-top: 0;
            padding-top: 0;
        }
    }

    &__course {
        background-repeat: repeat-x;
        background-image: url('../../../images/inhtml/courses-background-big.png');

        @media (--desktop-lg) and (--tablet-lg-min) and (--tablet-height) {
            background-image: url('../../../images/inhtml/courses-background-small.png');
        }

        @media (--tablet-lg) {
            background-image: url('../../../images/inhtml/courses-background-small.png');
            background-repeat: no-repeat;
            background-position-y: 40px;
        }

        @media (--tablet) {
            background-position-y: 0;
        }

        &-list {
            justify-content: space-between;
            background: var(--cl-white);

            .v-list__item {
                padding: 0;
                width: calc(50% - calc(var(--gs) * 2));
                margin-bottom: calc(var(--gs) * 4);

                @media (--desktop) {
                    width: calc(50% - var(--gs));
                    margin-bottom: calc(var(--gs) * 2);
                }

                @media (--tablet) {
                    width: 100%;
                }

                &::before {
                    display: none;
                }

                &:nth-child(odd):last-child {
                    @media (--tablet-min) {
                        margin-right: auto;
                        margin-left: auto;
                    }
                }

                &:nth-child(1):nth-last-child(2),
                &:nth-child(2):nth-last-child(1),
                &:nth-child(2n + 3),
                &:last-child {
                    @media (--tablet-min) {
                        margin-bottom: 0;
                    }
                }

                &:last-child {
                    @media (--tablet) {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &-card {
            height: calc(var(--gs) * 80);
            line-height: 1.25rem;
            @media (--desktop-lg) and (--tablet-lg-min) {
                height: calc(var(--gs) * 60);

                .card-course {
                    &__link {
                        font-size: 20px;
                        line-height: 24px;
                    }

                    &__body {
                        padding: calc(var(--gs) * 2) calc(var(--gs) * 4);
                    }

                    &__info {
                        max-height: 40px;
                        line-height: 20px;
                        font-size: 16px;

                        &.line-clamp {
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                        }
                    }

                    &__price {
                        .price-panel__btns {
                            flex-direction: row;
                        }
                    }
                }
            }

            @media (--tablet-lg) {
                height: calc(var(--gs) * 75);
            }
        }
    }

    &__partner {
        &-hl {
            font-size: 30px;
            line-height: 40px;
            @media (--tablet) {
                font-size: 20px;
                line-height: 28px;
            }
        }
        &-h2 {
            font-size: 24px !important;
            line-height: 34px !important;
            @media (--tablet) {
                font-size: 18px !important;
                line-height: 26px !important;
            }
        }

        &-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .v-list__item {
                padding: 0;
                width: calc(calc(100% / 6) - calc(var(--gs) * 2));

                &::before {
                    display: none;
                }

                @media (--desktop) {
                    width: calc(calc(100% / 3) - calc(var(--gs) * 2));
                }
            }
        }

        &-card {
            height: calc(var(--gs) * 16);
        }
    }

    &__catalog {
        &-list {
            justify-content: space-between;

            .v-list__item {
                padding: 0;
                width: calc(50% - calc(var(--gs) * 2));

                &::before {
                    display: none;
                }

                @media (--desktop) {
                    width: 100%;

                    & + .v-list__item {
                        margin-top: calc(var(--gs) * 4);
                    }
                }
            }
            .card-section__desc{
                font-size: 1rem;
            }
        }

        &-card {
            height: calc(var(--gs) * 25);
            filter: grayscale(1);
            &:hover {
                filter: none;
            }

            @media (--desktop-lg) and (--tablet-lg-min) and (--tablet-height) {
                .card-section__hl {
                    font-size: 20px;
                }

                .card-section__body {
                    padding: calc(var(--gs) * 3);
                }

                .card-section__link {
                    line-height: 20px;
                }
            }

            @media (--tablet) {
                height: auto;
                flex-direction: column;
                .card-section__body {
                    padding: calc(var(--gs) * 3);
                }
            }
        }
    }

    &__benefit {
        &-hl {
            font-size: 30px;
            line-height: 40px;
            @media (--tablet) {
                font-size: 20px;
                line-height: 28px;
            }
        }

        &-list {
            .v-list__item {
                width: calc(100% / 3);
                border-left: 1px solid var(--cl-divider-grey);

                @media (--tablet-lg-min) {
                    &:nth-child(3n + 1) {
                        border-left: none;
                    }
                }

                @media (--tablet-lg) {
                    width: 50%;
                    margin-bottom: calc(var(--gs) * 3);

                    &:nth-child(2n + 1) {
                        border-left: none;
                    }
                }
            }
            &__four {
                .v-list__item {
                    width: calc(100% / 4);
                    
                    @media (--tablet-lg) {
                        width: 50%;
                    }
                    @media (--mobile-lg) {
                        width: 100%;
                    }
                    @media (--tablet-lg-min) {
                        &:nth-child(3n + 1) {
                            border-left: 1px solid var(--cl-divider-grey);
                        }
                        &:nth-child(4n + 1) {
                            border-left: none;
                        }
                    }
                }
            }
        }
    }

    &__videos {
        &-btn {
            @media (--tablet) {
                width: 100%;
            }
        }

        &-slider {
            .slider__title {
                font-size: 30px;
                line-height: 40px;
                @media (--tablet) {
                    font-size: 20px;
                    line-height: 28px;
                }
            }

            .slider__header {
                @media (--tablet) {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }

            .slider__title-block {
                @media (--tablet) {
                    flex-direction: row;
                    margin-bottom: calc(var(--gs) * 2);
                }
            }
        }
    }

    &__teacher {
        &-slider {
            .slider__title {
                font-size: 30px;
                line-height: 40px;
                @media (--tablet) {
                    font-size: 20px;
                    line-height: 28px;
                }
            }

            .slider__title-block {
                @media (--tablet) {
                    flex-direction: row;
                    width: 100%;
                }
            }
        }
    }

    &__popular {
        &-slider {
            .slider__title-block {
                @media (--tablet) {
                    flex-direction: row;
                    width: 100%;
                }
            }
            .slider__slide {
                @media (--tablet) {
                    width: 90% !important;
                    &:last-child {
                        width: 100% !important;
                        margin-right: 0;
                    }
                }
            }
        }

        &-card {
            height: calc(var(--gs) * 70);
        }
    }

    &__teacher-company,
    &__student-company {
        &-hl {
            font-size: 30px;
            line-height: 40px;
            margin-bottom: calc(var(--gs) * 5);

            @media (--tablet) {
                font-size: 22px;
                line-height: 28px;
                margin-bottom: calc(var(--gs) * 2);
            }

            @media (--tablet) {
                font-size: 20px;
                line-height: 28px;
            }
        }

        &-table {
            margin-bottom: calc(var(--gs) * 4);

            @media (--tablet) {
                margin-bottom: calc(var(--gs) * 3);
            }
        }

        &-card {
            height: calc(var(--gs) * 14);
            padding: calc(var(--gs) * 3);

            @media (--tablet) {
                height: calc(var(--gs) * 10);
                padding: var(--gs);
            }

            &-img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                font-family: 'object-fit: contain';
            }
        }

        &-btn {
            display: block;
            margin: 0 auto;
            width: max-content;
                
            @media (--tablet) {
                width: 100%;
            }
        }
    }
    &__cooperation {
        &-desc {
            margin: calc(var(--gs) * 3) 0;
            font-size: 16px;
            &-text {
                display: flex;
                text-align: justify;
                @media (--tablet-lg) {
                    flex-wrap: wrap;
                    text-align: left;
                }
                @media (--tablet-lg-min) {
                    & .link {
                        margin-right: calc(var(--gs) * 4.5);
                    }
                }
            }
            &-col {
                width: 100%;
                @media (--tablet-lg-min) {
                    width: 50%;
                }
                & .link {
                    float: right;
                    margin-top: calc(var(--gs) * 3);
                }
            }
        }
        &__img {
            max-height: 200px;
        }
        @media (--tablet-lg-min) {
            &__img {
                max-height: 400px;
            }
            & .desc-col__left {
                margin-right: calc(var(--gs) * 3);
            }
            & .desc-col__right {
                  margin-left: calc(var(--gs) * 3);
            }
        }
        @media (--tablet) {
            & .desc-col__left {
                margin-bottom: calc(var(--gs) * 3);
            }
        }
    }
    &__interns {
        & p, & li {
            @media (--tablet-lg) {
                font-size: 15px;
                margin-bottom: calc(var(--g)/2);
            }
            @media (--tablet) {
                font-size: 14px;
                line-height: 20px;
            }
        }
        
        &-wraps {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        &-block {
            width: 49%;
            @media (--tablet-lg) {
                width: 100%;
            }
            
            &__style {
                margin-bottom: calc(var(--g)/2);
                border: 1px solid var(--cl-divider-grey);
                border-radius: 5px;
                padding: calc(var(--g));
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            
            & .index__partner-h2 {
                text-align: center;
                font-weight: 500;
                color: var(--cl-cyan);
                margin-bottom: 1rem;
            }
        }
        & .bullitt-lists li {
            line-height: 2;
        }
    }
    &__banner-e {
        @media (--tablet-min) {
            display: none;
        }
        & .header-main__banner-image {
            max-height: none;
        }
    }
    &__telegram {
        display: none;
        @media (--tablet-min) {
            display: flex;
            flex-wrap: wrap;
        }

        &__col-1 {
            width: 35%;
            text-align: center;
        }
        &__col-2 {
            width: 60%;
        }
        &__img {
            max-width: 150px;
        }
        &__btn {
            text-align: center;
            margin-top: calc(var(--gs) * 3);
        }
        &__text {
            font-weight: 500;
            font-size: 1.05rem;
        }
        @media (--tablet) {
            &__col-1, &__col-2 {
                width: 100%;
            }
            &__text {
                text-align: center;
            }
        }
    }
    &__services .slider__navigation-btn {
        margin-left: 0;
    }
    &__services .swiper-container {
        margin: 0 60px;
    }
    & .svg-line {
        margin-left: 8px;
    }
    &__pseudo-btn {
        & .btn {
            text-transform: none;
            cursor: default;
            margin-bottom: calc(var(--gs)*2);
            font-size: 16px;
            color: var(--cl-white);
            &:hover {
                background: var(--cl-cyan);
            }
            @media (--tablet-lg-min) {
                padding: var(--gs) calc(var(--gs)*4.5);
            }
        }
        & a {
            display: block;
            margin: calc(var(--gs)*1) auto 0;
            text-transform: uppercase;
            color: var(--cl-white);
            fill: var(--cl-white);
            text-align: right;
        }
    }
    &__bottom {
         margin-bottom: calc(var(--gs) * 15);
    }
}

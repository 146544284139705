.slider {
    display: block;
    position: relative;
    max-width: 100%;
    width: 100%;

    &__header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: calc(var(--gs) * 3);
    }

    &__addition {
        margin-left: auto;
        &:empty {
            display: none;
        }

        @media (--tablet) {
            width: 100%;
        }
    }

    &__title {
        margin-bottom: 0;
        margin-right: calc(var(--gs) * 2);

        &-block {
            display: flex;
            align-items: flex-end;

            @media (--tablet) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }

    &__link {
        margin-bottom: calc(var(--gs) * 0.5);
        margin-left: calc(var(--gs) * 4);

        @media (--tablet) {
            margin: 0;
            margin-top: calc(var(--gs) * 0.5);
        }
    }

    &__navigation {
        display: flex;
        position: relative;
        height: calc(var(--gs) * 6);

        &-btn {
            margin-left: calc(var(--gs) * 2);
            background: var(--cl-cyan);
            /*@media(--tablet) {
                background: none;
                &:hover {
                    background: none;
                }
            }*/
        }

        @media (--tablet) {
            display: none;
        }
        &-between {
            display: flex;
            position: absolute;
            top: 50%;
            width: 100%;
            justify-content: space-between;
            z-index: 9;
        }
    }

    &__slide {
        @media (--tablet) {
            transition: all var(--time) var(--timing-function);
            opacity: 0.4;
            /*width: 90% !important;
            margin-right: 16px;*/

            .index__cooperation-slider & {
                width: 100% !important;
                text-align: center;
                margin-right: 0;
            }
            
            /*&:last-child {
                width: 100% !important;
                margin-right: 0;
            }*/
            &-two {
                width: 50%!important;
            }
        }

        &.swiper-slide-active {
            @media (--tablet) {
                transition: all var(--time) var(--timing-function);
                opacity: 1;
            }
        }
    }

    /* .swiper-button-disabled {
        display: none;
    } */


    @media (--tablet) {
        & .btn--arrow .icon {
            /*margin: 0;*/
            margin: calc(var(--gs)*0.6) calc(var(--gs)*0.7);
        }
    }
}
.index__cooperation-slider {
    .slider__navigation-btn {
        margin-left: 0;
    }
    @media (--tablet-lg-min) {
        .swiper-slide-active {
            margin-left: 60px;
            margin-right: -30px !important;
        }
    }
}

.card-teacher {
    display: flex;

    &--small {
        display: block;
        text-align: center;
    }

    &__hl {
        font-weight: 500;
        margin-bottom: var(--gs);
        font-size: 20px;

        @media (--tablet) {
            font-size: 18px;
            line-height: 24px;
        }
    }

    &__preview {
        display: flex;
        margin-right: calc(var(--gs) * 8);

        @media (--tablet-lg) {
            margin-right: calc(var(--gs) * 4);
        }

        .card-teacher--small & {
            justify-content: center;
            margin: auto;
            margin-bottom: calc(var(--gs) * 2);
        }
    }

    &__body {
        flex: 1;
        &-link {
            color: var(--cl-global);
            &:hover {
                color: var(--cl-global);
            }
        }
    }

    &__img {
        border-radius: 50%;
        width: 184px;
        height: 184px;
        background: var(--cl-divider-grey);
        object-fit: cover;
        font-family: 'object-fit: cover';

        &--empty {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 184px;
            height: 184px;
        }
    }

    &__desc {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: calc(var(--gs) * 2);

        @media (--tablet-lg) {
            font-size: 14px;
            line-height: 20px;
        }

        @media (--tablet) {
            font-size: 13px;
        }

        &-link {
            font-size: inherit;
            margin-left: var(--gs);
        }
    }
}

.card-benefit {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-align: center;
    padding: calc(var(--gs) * 2);

    @media (--tablet) {
        padding: var(--gs);
    }

    &__header {
        font-size: 56px;
        line-height: 56px;
        color: var(--cl-cyan);
        font-weight: 300;

        @media (--tablet) {
            font-size: 36px;
            line-height: 56px;
        }
    }

    &__desc {
        font-size: 16px;
        line-height: 24px;

        /*@media (--tablet) {
            font-weight: 500;
            font-size: 11px;
            line-height: 15px;
        }*/
    }
}

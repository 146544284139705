.info-html {
    ul {
        li {
            padding-left: calc(var(--gs) * 4);
            margin-bottom: 16px;
            line-height: 1.5;

            &::before {
                content: '';
                background-image: svg-load('../../../images/svg/polygon.svg', 'fill: #FF4A81');
                width: 16px;
                height: 14px;
                position: absolute;
                left: 0;
                top: 4px;
            }
        }
        
        &.bullitt-lists li {
            &::before {
                background-image: url('../../../images/inhtml/bullitt.png');
                width: 24px;
                height: 24px;
                background-size: contain;
                top: 6px;
            }
        }
    }
}

.course-detail__description-full {
    iframe {
        @media (--tablet) {
            width:  100%;
        }
    }
}

